import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import moment from 'moment';


export default function AddBonus({availableWorkers}) {                
  
const [newBonus, setNewBonus] = useState({
  bonusDate: moment().format("YYYY-MM-DD"), // Устанавливаем текущую дату по умолчанию
});
const api_url = process.env.REACT_APP_API_URL;
const complex_name = localStorage.getItem('complex_name');
const [saveStatus, setSaveStatus] = useState("");
const [saveButtonDisable, setSaveButtonDisable] = useState(false);             

function handleChange(e) {
    setNewBonus({
      ...newBonus,
      [e.target.name]: e.target.value
    });
}

async function createBonus() {
    
    setSaveButtonDisable(true);                               //сразу выключаю кнопку сохранения, чтобы не нажимали дважды

    let worker_info = availableWorkers.find((s) => s.workerId === newBonus.workerId)
    let full_name = worker_info.fullName;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
        body: JSON.stringify({worker_id: newBonus.workerId, bonus_date: newBonus.bonusDate, full_name: full_name, bonus_amount: newBonus.bonusAmount, complex_name: complex_name, comment: newBonus.comment, bonus_category: newBonus.bonusCategory})
    };

    const saving_visit = await fetch(api_url+"/api/addBonus", requestOptions);

    const body = await saving_visit.json();
    if (body.message === "Bonus added successfully!") {
      setSaveStatus("success");
    }
    else {
      setSaveStatus("error");
      setSaveButtonDisable(false);
    }
}

return (
    <div className='edit-worker-modal'>
    <h1 className="modal-header"> Добавить премию </h1>
      <Box sx={{ maxWidth: 550, margin: `auto`, pt: 5, pb: 5 }}>

      <FormControl sx={{ mb: 1.5, mt: 1.5 }} fullWidth variant="outlined">
        {/* <InputLabel id="date-select-label">Дата:</InputLabel> */}
        <TextField
          type="date"
          name="bonusDate"
          label="Дата"
          variant="outlined"
          value={newBonus.bonusDate}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </FormControl>
      
        <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
        <InputLabel id="demo-simple-select-label">Сотрудник:</InputLabel>
        <Select
          value={newBonus.worker_id}
          label="Сотрудник:"
          name="workerId"
          fullWidth
          onChange={handleChange}
        >
        {availableWorkers.map(({workerId, fullName}) => (                           //листим каталог работ
        <MenuItem key={workerId} value={workerId}> {fullName} </MenuItem>
        ))}
        </Select>
        </FormControl>

        <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
        <InputLabel htmlFor="component-outlined">Сумма</InputLabel>
        <OutlinedInput
          name="bonusAmount"
          value={newBonus.bonusAmount}
          onChange={handleChange}
          label="Сумма"
        />
        </FormControl>

        <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
        <InputLabel id="demo-simple-select-label">Категория премии</InputLabel>
        <Select
            id="bonus_category"
            name="bonusCategory"
            value={newBonus.bonusCategory}
            label="Категория премии"
            onChange={handleChange}
        >
        <MenuItem value="regular_bonus">Обычная премия</MenuItem>
        <MenuItem value="birthday_bonus">Премия за ДР</MenuItem>
        <MenuItem value="annual_bonus">Годовая премия</MenuItem>
        </Select>
        </FormControl>

        <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
        <InputLabel htmlFor="component-outlined">Комментарий</InputLabel>
        <OutlinedInput
          id="comment"
          name="comment"
          value={newBonus.comment}
          onChange={handleChange}
          label="Комментарий"
        />
        </FormControl>
        
        <Button variant="contained"
            sx={{mb:1.5, mt:1.5}} fullWidth
            disabled={saveButtonDisable}
            color="success"
            className="mr10"
            onClick={() => createBonus()}>Добавить премию</Button>
        {saveStatus === "error" && <Alert severity="error">Ошибка, проверьте правильность введенных данных!</Alert>}
        {saveStatus === "success" && <Alert severity="success">Изменения успешно сохранены!</Alert>}
        </Box>
    </div>
)}