import React, { useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

export default function FinanceInfo() {
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const api_url = process.env.REACT_APP_API_URL;
    const complexName = localStorage.getItem('complex_name');
    const [workerFinanceInfo, setWorkerFinanceInfo] = useState([]);
    const [totalFines, setTotalFines] = useState(0);
    const [totalAdvance, setTotalAdvance] = useState(0);
    const [totalBonuses, setTotalBonuses] = useState(0);
    const [totalSalary, setTotalSalary] = useState(0);
    const [totalToPay, setTotalToPay] = useState(0); 
    const [isLoading, setIsLoading] = useState(true);        

    React.useEffect(() => {                                         //функция, которая вызывается при загрузке страницы
    
        async function getWorkerFinanceInfo() {
          setIsLoading(true); //начало загрузки
          try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
                body: JSON.stringify({ start_date: startDate, end_date: endDate, complex_name: complexName})
            };
            const response = await fetch(api_url+"/api/workerFinanceInfo", requestOptions);
            const body = await response.json();
            setWorkerFinanceInfo(body.map(({ worker_id, worker_name, worker_fines, worker_advances, worker_bonuses, worker_salary, worker_to_pay, consecutiveMonths, monthlyDetails, availableBonusSum }) => 
                ({ worker_id: worker_id, worker_name: worker_name, worker_fines: worker_fines, worker_advances: worker_advances, worker_bonuses: worker_bonuses, worker_salary: worker_salary, worker_to_pay: worker_to_pay, consecutiveMonths: consecutiveMonths, monthlyDetails: monthlyDetails, availableBonusSum: availableBonusSum
            })));
            
            let total_fines = 0;
            let total_salary = 0;
            let total_advance = 0;
            let total_bonuses = 0;
            let total_to_pay = 0;
            for(var i=0; i < body.length; i++){
                total_fines = total_fines + body[i].worker_fines;
                total_advance = total_advance + body[i].worker_advances;
                total_bonuses = total_bonuses + body[i].worker_bonuses;
                total_salary = total_salary + body[i].worker_salary;
                total_to_pay = total_to_pay + body[i].worker_to_pay;
            }
            setTotalFines(total_fines);
            setTotalAdvance(total_advance);
            setTotalBonuses(total_bonuses);
            setTotalSalary(total_salary);
            setTotalToPay(total_to_pay);
        } catch (error) {
            console.error("Failed to fetch worker finance info", error);
        }
        setIsLoading(false);
    }
    getWorkerFinanceInfo();
    }, [startDate, endDate]);

    return (
        <>
            <head>
                <title>Финансы</title>
                <meta property="og:title" content="Dashboard" key="title" />
            </head>

            <Box sx={{pt:5, pb:5}} fullWidth>
            <TextField
                id="start_date"
                label="Start Date"
                type="date"
                defaultValue={startDate}
                sx={{ m: 1.5, width: 220}}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => setStartDate(e.target.value)}
            />

            <TextField
                id="end_date"
                label="End Date"
                type="date"
                defaultValue={endDate}
                sx={{ m: 1.5, width: 220 }}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => setEndDate(e.target.value)}
            />
            </Box>

            <Box display="flex" justifyContent="space-around" alignItems="center" p={2} bgcolor="background.paper">
                <Typography variant="subtitle1">Всего ЗП: {totalSalary}</Typography>
                <Typography variant="subtitle1">Всего авансов: {totalAdvance}</Typography>
                <Typography variant="subtitle1">Всего бонусов: {totalBonuses}</Typography>
                <Typography variant="subtitle1">Всего штрафов: {totalFines}</Typography>
                <Typography variant="subtitle1">К выдаче: {totalToPay}</Typography>
            </Box>

            {isLoading ? (
            <Box display="flex" justifyContent="center" my={4}>
                <CircularProgress />
            </Box>
            ) : (
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Сотрудник</TableCell>
                <TableCell>Сумма ЗП</TableCell>
                <TableCell>Авансы</TableCell>
                <TableCell>Штрафы</TableCell>
                <TableCell>Премии</TableCell>
                <TableCell>К Выдаче</TableCell>
                <TableCell>Годовая премия</TableCell>
                <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {workerFinanceInfo.map(({ worker_id, worker_name, worker_salary, worker_advances, worker_fines, worker_bonuses, worker_to_pay, consecutiveMonths, availableBonusSum }) => (
            <TableRow>
                <TableCell>{worker_name}</TableCell>
                <TableCell>{worker_salary}</TableCell>
                <TableCell>{worker_advances}</TableCell>
                <TableCell>{worker_fines}</TableCell>
                <TableCell>{worker_bonuses}</TableCell>
                <TableCell>{worker_to_pay}</TableCell>
                <TableCell>{consecutiveMonths} мес./{availableBonusSum} руб.</TableCell>
            </TableRow>
            ))}
            </TableBody>
            </Table>
            </TableContainer>
            )}
        </>
    )
}

