import React, { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

export default function EditService({availableServices, servicesInfo, setServicesInfo, availableWorkers }) {

    const complexName = localStorage.getItem('complex_name');
    const api_url = process.env.REACT_APP_API_URL;
    const [outsourceWorkerSelected, setOutsourceWorkerSelected] = useState(false);    //стейт, который включается при выборе работника аутсорс
    //const OUTSOURCE_WORKER_ID = process.env.OUTSOURCE_WORKER_ID;               //идентификатор работника аутсорс
    const OUTSOURCE_WORKER_ID = ["c1f400d9-7e6b-4d8c-b049-d104f82418ad", "f1bcec02-4918-4570-8bc7-21aeb84e5309"];               //идентификатор работника аутсорс

    console.log(servicesInfo);

    function findServiceById(id) {
        return availableServices.find((element) => {
            return element.serviceId === id;
        })
    }

    const handleServiceSelect = (e, index) => {                             //функция, которая вызывается после выбора работы из дропдауна
        const list = [...servicesInfo];
        list[index].serviceId = e.target.value;
        list[index].price = findServiceById(e.target.value).price;
        list[index].salary = findServiceById(e.target.value).salary;
        list[index].minRank = findServiceById(e.target.value).minRank;
        list[index].adminSalary = findServiceById(e.target.value).adminSalary;
        setServicesInfo(list);
    };

    const handleServiceNumberSelect = (e, index) => {                             //функция, которая вызывается после выбора работы из дропдауна
        const result = e.target.value.replace(/\D/g, '');
        const list = [...servicesInfo];
        list[index].serviceNumber = result;
        setServicesInfo(list);
    };

    const handleOutsourcePaymentChange = (e, index) => {                             //функция, которая вызывается после ввода суммы аутсорса
        const list = [...servicesInfo];
        list[index].outsourcePayment = e.target.value;
        setServicesInfo(list);
    };

    const handleRemoveService = (index) => {                               //функция, которая вызывается при удалении работы
        const list = [...servicesInfo];
        list.splice(index, 1);
        setServicesInfo(list);
        console.log("remove");
    };
    
    const handleAddService = () => {                                       //функция, которая вызывается при добавлении работы
        setServicesInfo([...servicesInfo, {serviceId: availableServices[0]?.serviceId, serviceNumber: 1, price: availableServices[0]?.price, salary: availableServices[0]?.salary, adminSalary: availableServices[0]?.adminSalary, chosenWorkers: []}]);    //при добавлении нового сервиса - автоматически подставляется первая услуга
        console.log("add");
    };

    const handleChosenWorkerSelect = (e, index) => {                             //функция, которая вызывается после выбора сотрудника из дропдауна
        console.log("select_start" + e.target.value + index);
        const list = [...servicesInfo];
        let workers_list = [...servicesInfo[index].chosenWorkers];
        workers_list = e.target.value;
        console.log(workers_list);
        list[index].chosenWorkers = workers_list;
        setServicesInfo(list);

        // Проверка, содержит ли список выбранных сотрудников аутсорсера
        // const containsSpecialWorker = workers_list.includes(OUTSOURCE_WORKER_ID);
        const containsSpecialWorker = OUTSOURCE_WORKER_ID.some(id => workers_list.includes(id));
        setOutsourceWorkerSelected(containsSpecialWorker);
    };
   
    return (
        <Box sx={{ maxWidth: 400, margin: `auto`, pt: 1}}>
        <div className="modal-form">
            <label className="add-service-header">Выберите услуги:</label>
            {servicesInfo && servicesInfo.map((x, i) => (                             //создаем select по количеству выбранных работ
            <div key={i}>
                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Название:</InputLabel>
                <Select
                    key = {i+1}
                    value={servicesInfo[i]?.serviceId}
                    label="Название:"
                    name="serviceChoosing"
                    fullWidth
                    onChange={e => handleServiceSelect(e, i)}
                    MenuProps={{ PaperProps: { sx: { textAlign: 'left' } } }}
                >
                    {availableServices && availableServices.map(({serviceId, serviceName, serviceCategory}) => (                           //листим каталог работ
                    <MenuItem key={serviceId} value={serviceId}> {serviceCategory + "/" + serviceName} </MenuItem>
                    ))}
                </Select>
                </FormControl>

                <TextField
                    sx={{mb:1.5, mt:1.5}}
                    fullWidth
                    size="small"
                    variant="outlined"
                    key = {i+2}
                    value={servicesInfo[i].serviceNumber}
                    label="Количество:"
                    name="servicesNumber"
                    onChange={e => handleServiceNumberSelect(e, i)}
                />
                
                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth size="small">
                <InputLabel id="demo-multiple-checkbox-label">Сотрудники:</InputLabel>
                <Select
                    key = {i+2}
                    value={servicesInfo[i].chosenWorkers}
                    label="Сотрудники:"
                    name="workersChosing"
                    fullWidth
                    onChange={e => handleChosenWorkerSelect(e, i)}
                    multiple
                    input={<OutlinedInput id="select-multiple-chip" label="Сотрудники:" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={availableWorkers && availableWorkers.find((item) => item.workerId === value).fullName} />
                          ))}
                        </Box>
                    )}
                >   
                    {availableWorkers && availableWorkers.filter(person =>
                    person.rank >= servicesInfo[i].minRank
                    ).map((person) => (                          
                    <MenuItem key={person.workerId} value={person.workerId}> {person.fullName} </MenuItem>
                    ))}
                 </Select>
                </FormControl>

                {/* {servicesInfo[i].chosenWorkers.includes(OUTSOURCE_WORKER_ID) && (     //если среди выполняющих работу был аутсорсер, то выведется доп поле */}
                { OUTSOURCE_WORKER_ID.some(id => servicesInfo[i].chosenWorkers.includes(id)) && (
                <TextField
                    variant="outlined"
                    value = {servicesInfo[i].outsourcePayment}
                    label="ЗП для аутсорсера:"
                    name="outsourcePayment"
                    size="small"
                    sx={{mb:1.5, mt:1.5}}
                    fullWidth
                    onChange={e => handleOutsourcePaymentChange(e, i)}
                />
                )}

                {servicesInfo.length>1 && <button className="add-button" onClick={() => handleRemoveService(i)}>Удалить работу</button>}
            </div>
            ))}
            <button name="addServiceButton" className="add-button" onClick={handleAddService}><a>Добавить услугу</a></button>
        </div>
        </Box>
    );
}