import React, { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

export default function EditGoods({goodsInfo, setGoodsInfo, availableGoods}) {

    function findGoodsById(id) {
        return availableGoods.find((element) => {
            return element.goodsId === id;
        })
    }

    const handleGoodsSelect = (e, index) => {                             //функция, которая вызывается после выбора работы из дропдауна
        const list = [...goodsInfo];
        list[index].goodsId = e.target.value;
        list[index].price = findGoodsById(e.target.value).price;
        list[index].salary = findGoodsById(e.target.value).salary;
        setGoodsInfo(list);
    };

    const handleGoodsNumberSelect = (e, index) => {                             //функция, которая вызывается после выбора работы из дропдауна
        const result = e.target.value.replace(/\D/g, '');
        const list = [...goodsInfo];
        list[index].goodsNumber = result;
        setGoodsInfo(list);
    };

    const handleRemoveGoods = (index) => {                               //функция, которая вызывается при удалении товара
        const list = [...goodsInfo];
        list.splice(index, 1);
        setGoodsInfo(list);
        console.log("remove");
    };
    
    const handleAddGoods = () => {                                       //функция, которая вызывается при добавлении товара
        setGoodsInfo([...goodsInfo, {serviceId: availableGoods[0].goodsId, goodsNumber: 1, price: availableGoods[0].price, salary: availableGoods[0].salary}]);     //при добавлении нового сервиса - автоматически подставляется первый товар из списка
        console.log("add");
    };
   
    return (
        <div className="modal-form">
            <label className="add-service-header">Выберите товары:</label>
            {goodsInfo && goodsInfo.map((x, i) => (                             //создаем select по количеству выбранных товаров
            <div key={i}>
<               FormControl sx={{mb:1.5, mt:1.5}} fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Название:</InputLabel>
                <Select
                    key = {i+1}
                    value={goodsInfo[i]?.goodsId}
                    label="Название:"
                    name="goodsChoosing"
                    fullWidth
                    onChange={e => handleGoodsSelect(e, i)}
                    MenuProps={{ PaperProps: { sx: { textAlign: 'left' } } }}
                >
                    {availableGoods && availableGoods.map(({goodsId, goodsName, goodsCategory}) => (                          //листим каталог работ
                    <MenuItem key={goodsId} value={goodsId}> {goodsCategory + "/" + goodsName} </MenuItem>
                    ))}
                </Select>
                </FormControl>

                <TextField
                    sx={{mb:1.5, mt:1.5}}
                    fullWidth
                    size="small"
                    variant="outlined"
                    key = {i+2}
                    value={goodsInfo[i]?.goodsNumber}
                    label="Количество:"
                    name="goodsNumber"
                    onChange={e => handleGoodsNumberSelect(e, i)}
                />
                <br></br>
                {goodsInfo.length>1 && <button className="add-button" onClick={() => handleRemoveGoods(i)}>Удалить</button>}
            </div>
            ))}
            <button name="addGoodsButton" className="add-button" onClick={handleAddGoods}><a>Добавить товар</a></button>
        </div>
    );
}